<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-signature-freehand"
                title="Vertrag zur Auftragsverarbeitung (AV-Vertrag)"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Die Verarbeitung durch einen Auftragsverarbeiter –
                      vorliegend die Mira Digital Solutions UG
                      (haftungsbeschränkt) – erfolgt auf der Grundlage eines
                      Vertrags oder eines anderen Rechtsinstruments nach dem
                      Unionsrecht oder dem Recht der Mitgliedstaaten, der bzw.
                      das den Auftragsverarbeiter in Bezug auf den
                      Verantwortlichen bindet und in dem Gegenstand und Dauer
                      der Verarbeitung, Art und Zweck der Verarbeitung, die Art
                      der personenbezogenen Daten, die Kategorien betroffener
                      Personen und die Pflichten und Rechte des Verantwortlichen
                      festgelegt sind, vgl. Art. 28 Abs. 3 DSGVO. Gem. Art. 28
                      Abs. 9 ist der Vertrag oder das andere Rechtsinstrument
                      schriftlich abzufassen, was auch in einem elektronischen
                      Format erfolgen kann.
                      <br /><br />
                      <!-- Zum Abschluss eines Vertrags zur Auftragsverarbeitung
                      kannst Du das verlinkte Formular verwenden, um den
                      Vertrag abzuschließen. -->
                      Zum Abschluss eines Vertrags zur Auftragsverarbeitung
                      kontaktiere uns gerne per E-Mail über die folgende
                      Schaltfläche. Die E-Mail muss unbedingt Kontaktdaten des
                      Vertragspartners erhalten sowie die ID der Organisation.
                      <br />
                      <v-btn
                        color="success"
                        text
                        depressed
                        class="mt-3"
                        @click="askForAV()"
                      >
                        <v-icon left> mdi-shield-edit </v-icon>
                        AV-Vertrag abschließen
                      </v-btn>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="subtitle-1">
                      Kontaktieren uns, solltest Du zudem weitere Informationen
                      oder Unterstützung benötigen.
                    </div>
                    <v-btn
                      color="success"
                      text
                      depressed
                      class="mt-3"
                      @click="contactViaMail()"
                    >
                      <v-icon left> mdi-at </v-icon>
                      Kontakt aufnehmen
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-file-document-multiple"
                title="Datenschutzerklärung und AGB"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Wir verweisen weiter auf die
                      <a href="#" class="text-decoration-none"
                        >Datenschutzerklärung</a
                      >
                      sowie
                      <a href="#" class="text-decoration-none"
                        >allgemeinen Geschäftsbedingungen</a
                      >.
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "admin-privacy-tab-general",
  components: {
    Subheader,
  },
  methods: {
    askForAV() {
      window.location.href =
        "mailto:support@mira-one.com?subject=Kontaktaufnahme%20bzgl.%20AV-Vertrag";
    },
    contactViaMail() {
      window.location.href =
        "mailto:support@mira-one.com?subject=Kontaktaufnahme%20bzgl.%20Datenschutz";
    },
  },
};
</script>
