<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-history"
                title="Versionsverlauf der Datenschutzerklärungen der Organisation"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <div class="mt-7">
                  <v-data-iterator :items="privacyPolicyVersions">
                    <template v-slot:default="props">
                      <v-row>
                        <v-col
                          v-for="item in props.items"
                          :key="item.meta.id"
                          cols="12"
                        >
                          <v-card link @click="routeDetails(item.meta.id)">
                            <v-card-title class="subheading">
                              Version {{ item.versionNumber }} –
                              {{ item.meta.createdAt }}
                              <v-spacer></v-spacer>
                              <v-chip
                                color="light-blue lighten-4"
                                class="font-weight-medium light-blue--text text--darken-4"
                                >Aktuelle Version</v-chip
                              >
                            </v-card-title>
                            <v-card-subtitle>
                              {{ item.summary }}
                            </v-card-subtitle>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_PRIVACY_POLICY_VERSIONS } from "@/store/action-types.js";
export default {
  name: "admin-privacy-tab-policy",
  components: {
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Neue Version",
          icon: "mdi-text-box-plus",
          actionStyle: "textBtn",
          function: this.createItem,
        },
      ],
    };
  },
  computed: {
    policyVersions() {
      return this.$store.state.admin.privacyPolicyVersions;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_PRIVACY_POLICY_VERSIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      this.$router.push({
        name: "admin-privacy-tab-policy-policy-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>
