<template>
  <div>
    <NavigationBar
      titel="Datenschutz"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>

    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "admin-privacy",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `privacy/general`,
      tabs: [
        { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Datenschutzerklärung (Organisation)", route: `policy` },
      ],
    };
  },
};
</script>

<style>
/* .v-tabs-items {
    background-color: transparent !important;
  } */
</style>
