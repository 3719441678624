<template>
  <div>
    <NavigationBar
      titel="Neue Version für Datenschutzerklärung erstellen"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-stepper v-model="stepper" vertical>
                <v-stepper-step :complete="stepper > 1" step="1">
                  Typ
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-form
                          v-model="formIsValid"
                          @submit.prevent="submitData"
                        >
                          <v-row> </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        right
                        color="primary"
                        :disabled="!formIsValid"
                        @click="stepper++"
                      >
                        Weiter
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 2" step="2">
                  Inhalt
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12"> </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn depressed plain text @click="stepper--">
                        Zurück
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn depressed right color="primary" @click="stepper++">
                        Weiter
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 3" step="3">
                  Zusammenfassung
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12"> </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn depressed plain text @click="stepper--">
                        Zurück
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn depressed right color="primary" @click="stepper++">
                        Weiter
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 4" step="4">
                  Veröffentlichung
                </v-stepper-step>

                <v-stepper-content step="4">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert text color="primary" type="info">
                              <div class="title">Hinweis</div>
                              <div class="mt-2">
                                Das Veröffentlichen einer neuen Version der
                                Datenschutzerklärung kann nicht widerrufen
                                werden.
                                <br />
                                <br />
                                Alle Mitglieder der Organisation erhalten eine
                                E-Mail mit dem Hinweis auf die neue
                                Datenschutzerklärung und werden in der Anwendung
                                aufgefordert, in die neue Version der
                                Datenschutzerklärung aktiv einzuwilligen.
                              </div>
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                  <v-card-actions>
                    <v-btn depressed plain text @click="stepper--">
                      Zurück
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      right
                      color="primary"
                      @click="publishPolicy"
                    >
                      Neue Datenschutzerklärung veröffentlichen
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { ADMIN } from "@/store/modules.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import { CREATE_PRIVACY_POLICY_VERSION } from "@/store/action-types.js";
// import { ADMINISTRATION_SECURITY_ROLES_create } from "@/data/permission-types.js";
export default {
  name: "admin-privacy-tab-policy",
  components: { NavigationBar },
  data() {
    return {
      stepper: 1,
      formIsValid: false,
    };
  },
  computed: {
    policyVersions() {
      return this.$store.state.admin.privacyPolicyVersions;
    },
  },
  created() {
    //   this.initialize();
  },
  methods: {
    initialize() {},
    createItem() {
      this.$store.dispatch(`${ADMIN}${CREATE_PRIVACY_POLICY_VERSION}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
